.terms-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  background: linear-gradient(to bottom, #f8f9fa 0%, #ffffff 100%);
  min-height: 100vh;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.terms-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.terms-title {
  color: #1a237e;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 1rem;
}

.terms-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(to right, #1a237e, #3949ab);
  border-radius: 2px;
}

.terms-date {
  color: #666;
  text-align: center;
  margin-bottom: 2.5rem;
  font-style: italic;
  font-size: 1.1rem;
}

.terms-intro {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  border-left: 4px solid #1a237e;
  color: #2c3e50;
}

.terms-section {
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.terms-section:hover {
  background-color: #f8f9fa;
  transform: translateY(-2px);
}

.terms-section h2 {
  color: #1a237e;
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
  padding-bottom: 0.8rem;
  border-bottom: 2px solid #e0e0e0;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.terms-section h2::before {
  content: '§';
  margin-right: 10px;
  color: #3949ab;
  font-weight: bold;
}

.terms-section p {
  margin-bottom: 1.2rem;
  line-height: 1.8;
  color: #37474f;
}

.terms-section ul {
  list-style-type: none;
  margin-left: 1rem;
  margin-bottom: 1.2rem;
}

.terms-section li {
  margin-bottom: 0.8rem;
  line-height: 1.8;
  color: #37474f;
  position: relative;
  padding-left: 1.5rem;
}

.terms-section li::before {
  content: '•';
  color: #3949ab;
  font-weight: bold;
  position: absolute;
  left: 0;
}

@media (max-width: 768px) {
  .terms-container {
    padding: 1.5rem 1rem;
  }

  .terms-content {
    padding: 1.5rem;
  }

  .terms-title {
    font-size: 2rem;
  }

  .terms-section h2 {
    font-size: 1.4rem;
  }

  .terms-intro {
    padding: 1rem;
  }
}

/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.terms-content {
  animation: fadeIn 0.8s ease-out;
}

/* Estilos para scroll suave */
html {
  scroll-behavior: smooth;
}

/* Estilizar la barra de desplazamiento */
.terms-container::-webkit-scrollbar {
  width: 8px;
}

.terms-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.terms-container::-webkit-scrollbar-thumb {
  background: #3949ab;
  border-radius: 4px;
}

.terms-container::-webkit-scrollbar-thumb:hover {
  background: #1a237e;
}

.home-logo {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-logo:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .home-logo {
    width: 40px;
    height: 40px;
    top: 10px;
    left: 10px;
    padding: 6px;
  }

  .theme-toggle {
    width: 40px;
    height: 40px;
    top: 10px;
    right: 10px;
  }
}

/* Ajustes específicos para pantallas muy pequeñas */
@media (max-width: 360px) {
  .home-logo {
    width: 35px;
    height: 35px;
    padding: 5px;
  }

  .theme-toggle {
    width: 35px;
    height: 35px;
  }
}

.theme-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1000;
}

.theme-toggle:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Estilos para modo oscuro */
.dark-mode {
  background: #1a1a2e;
}

.dark-mode .terms-content {
  background-color: #1f1f35;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.dark-mode .terms-title {
  color: #ffffff;
}

.dark-mode .terms-date {
  color: #9ca3af;
}

.dark-mode .terms-intro {
  background-color: #2a2a45;
  border-left: 4px solid #3949ab;
  color: #e2e8f0;
}

.dark-mode .terms-section {
  color: #e2e8f0;
}

.dark-mode .terms-section:hover {
  background-color: #2a2a45;
}

.dark-mode .terms-section h2 {
  color: #fff;
  border-bottom: 2px solid #3949ab;
}

.dark-mode .terms-section p,
.dark-mode .terms-section li {
  color: #e2e8f0;
}

.dark-mode .theme-toggle {
  background-color: #2a2a45;
  color: #fff;
}

.dark-mode .home-logo {
  background-color: #2a2a45;
}

@media (max-width: 768px) {
  .theme-toggle {
    width: 40px;
    height: 40px;
    top: 10px;
    right: 10px;
    font-size: 1.2rem;
  }
}

.email-link {
  color: #3949ab;
  text-decoration: none;
  transition: all 0.3s ease;
  border-bottom: 1px dotted #3949ab;
}

.email-link:hover {
  color: #1a237e;
  border-bottom: 1px solid #1a237e;
}

/* Estilo para modo oscuro */
.dark-mode .email-link {
  color: #8c9eff;
  border-bottom: 1px dotted #8c9eff;
}

.dark-mode .email-link:hover {
  color: #c5cae9;
  border-bottom: 1px solid #c5cae9;
}