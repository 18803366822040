.delete-container {
  min-height: 100vh;
  padding: 3rem 2rem;
  background: linear-gradient(to bottom, #f8f9fa 0%, #ffffff 100%);
  transition: all 0.3s ease;
}

.delete-content {
  max-width: 800px;
  margin: 4rem auto 0;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.delete-title {
  color: #1a237e;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 700;
}

.delete-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #4a5568;
}

.video-container {
  width: 100%;
  margin: 2rem 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.tutorial-video {
  width: 100%;
  display: block;
  border-radius: 10px;
}

.steps-container {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  border-left: 4px solid #1a237e;
}

.steps-container h2 {
  color: #1a237e;
  margin-bottom: 1rem;
}

.steps-container ol {
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.steps-container li {
  margin-bottom: 0.8rem;
  line-height: 1.6;
  color: #4a5568;
}

.warning-text {
  padding: 1rem;
  background-color: #fff3cd;
  border-left: 4px solid #ffc107;
  border-radius: 5px;
  color: #856404;
  margin-top: 1.5rem;
}

/* Estilos para modo oscuro */
.dark-mode.delete-container {
  background: #1a1a2e;
}

.dark-mode .delete-content {
  background-color: #1f1f35;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.dark-mode .delete-title {
  color: #fff;
}

.dark-mode .delete-description {
  color: #e2e8f0;
}

.dark-mode .steps-container {
  background-color: #2a2a45;
  border-left-color: #3949ab;
}

.dark-mode .steps-container h2 {
  color: #fff;
}

.dark-mode .steps-container li {
  color: #e2e8f0;
}

.dark-mode .warning-text {
  background-color: #2c2410;
  border-left-color: #ffc107;
  color: #ffd54f;
}

@media (max-width: 768px) {
  .delete-container {
    padding: 2rem 1rem;
  }

  .delete-content {
    padding: 1.5rem;
    margin-top: 3rem;
  }

  .delete-title {
    font-size: 2rem;
  }
}

.delete-account-button {
  background-color: #ff4444;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 20px;
  transition: background-color 0.3s ease;
}

.delete-account-button:hover {
  background-color: #ff0000;
}

.dark-mode .delete-account-button {
  background-color: #cc0000;
}

.dark-mode .delete-account-button:hover {
  background-color: #990000;
}
