.App {
  text-align: center;
  min-height: 100vh;
  transition: all 0.3s ease;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background: linear-gradient(to bottom, #f8f9fa 0%, #ffffff 100%);
  color: #282c34;
  transition: all 0.3s ease;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Estilos para modo oscuro */
.dark-mode .App {
  background-color: #1a1a2e;
  color: #ffffff;
}

.dark-mode .App-header {
  background: #1a1a2e;
  color: #ffffff;
}

/* Aseguramos que las transiciones sean suaves */
* {
  transition: background-color 0.3s ease, color 0.3s ease;
}

@media (max-width: 768px) {
  .App-header {
    padding: 1rem;
  }
}
