.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 15px 25px;
  background-color: transparent;
}

.navbar-left {
  position: absolute;
  top: 15px;
  left: 25px;
}

.navbar-right {
  position: absolute;
  top: 15px;
  right: 25px;
}

.button-container {
  display: flex;
  gap: 15px;
  align-items: center;
}

.home-logo {
  width: 42px;
  height: 42px;
  cursor: pointer;
  border-radius: 50%;
  padding: 6px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  object-fit: contain;
}

.home-logo:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.theme-toggle,
.language-toggle {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .language-toggle {
    margin-top: 60px;
    margin-right: -10px;
  }
}

@media (min-width: 768px) {
  .language-toggle {
    margin-top: 60px;
    margin-right: -5px;
  }
}

.theme-toggle:hover,
.language-toggle:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  background-color: rgba(255, 255, 255, 0.9);
}

/* Estilos para modo oscuro */
.dark-mode .home-logo,
.dark-mode .theme-toggle,
.dark-mode .language-toggle {
  background-color: rgba(42, 42, 69, 0.8);
  color: #fff;
}

.dark-mode .theme-toggle:hover,
.dark-mode .language-toggle:hover {
  background-color: rgba(42, 42, 69, 0.9);
}

@media (max-width: 768px) {
  .navbar-left {
    top: 12px;
    left: 20px;
  }

  .navbar-right {
    top: 12px;
    right: 20px;
  }

  .button-container {
    gap: 12px;
  }

  .home-logo,
  .theme-toggle,
  .language-toggle {
    width: 38px;
    height: 38px;
  }

  .home-logo {
    padding: 5px;
  }
}

@media (max-width: 360px) {
  .navbar-left {
    top: 10px;
    left: 15px;
  }

  .navbar-right {
    top: 10px;
    right: 15px;
  }

  .button-container {
    gap: 8px;
  }

  .home-logo,
  .theme-toggle,
  .language-toggle {
    width: 35px;
    height: 35px;
  }

  .home-logo {
    padding: 4px;
  }
}
