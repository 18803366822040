.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #f8f9fa 0%, #ffffff 100%);
  transition: all 0.3s ease;
  padding: 2rem;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  max-width: 1200px;
  width: 100%;
}

.rotating-image-container {
  animation: rotate linear infinite;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.rotating-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
}

.terms-button {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  color: #ffffff;
  background: linear-gradient(to right, #3949ab, #5c6bc0);
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

.terms-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  background: linear-gradient(to right, #5c6bc0, #3949ab);
}

.terms-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Estilos para modo oscuro */
.dark-mode {
  background: #1a1a2e;
  color: #ffffff;
}

.dark-mode .rotating-image {
  filter: drop-shadow(0 4px 8px rgba(255, 255, 255, 0.1));
}

.dark-mode .terms-button {
  background: linear-gradient(to right, #3949ab, #7986cb);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.dark-mode .terms-button:hover {
  background: linear-gradient(to right, #7986cb, #3949ab);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .rotating-image-container {
    width: 200px;
    height: 200px;
  }

  .terms-button {
    padding: 0.8rem 1.6rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .rotating-image-container {
    width: 150px;
    height: 150px;
  }

  .terms-button {
    padding: 0.7rem 1.4rem;
    font-size: 0.9rem;
    letter-spacing: 1px;
  }
}

.buttons-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.terms-button,
.delete-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.terms-button {
  background-color: #1a237e;
  color: white;
}

.terms-button:hover {
  background-color: #0d1657;
}

.delete-button {
  background-color: #ff4444;
  color: white;
}

.delete-button:hover {
  background-color: #ff0000;
}

.dark-mode .terms-button {
  background-color: #3949ab;
}

.dark-mode .terms-button:hover {
  background-color: #283593;
}

.dark-mode .delete-button {
  background-color: #cc0000;
}

.dark-mode .delete-button:hover {
  background-color: #990000;
}